/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useMask } from "@react-input/mask";
import { useNavigate } from "react-router-dom";


import getLangs from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import NetServices from "src/utils/netservices";
import {
  ICountry,
  IFormError,
  IUserContactRequest,
} from "src/models/smarttypes";
import LoadingPage from "src/components/LoadingPage";

const languages = getLangs();
const appFxs = generalFxs();
const emptyRecord: IUserContactRequest = {
  IdUser: 0,
  Email: "",
  FirstName: "",
  LastName: "",
  Sex: "0",
  Title: "0",
  LanguageType: "0",
  Interest: "0",
  MobilePhone: "",
  Subject: "",
  Message: "",
  CanReceiveMonthlyEmail: false,
  SecureCode: "",
  Country: "RW",
  City: "",
  DateRegistration: new Date(),
};
const senderTitles: string[] = [languages.mr, languages.mrs, languages.ms];
const formLanguages: string[] = [
  languages.english_m,
  languages.french_m,
  languages.rwanda_m,
  languages.swahili_m,
];
const formsexes: string[] = [languages.male, languages.female];
const countries: ICountry[] = [
  { name: "Rwanda", dial_code: "+250", code: "RW" },
];

const ContactPage = () => {
  document.title = `${languages.contact} | ${languages.pers_resume_serv}`;

  const navigate = useNavigate();

  const [saving, setSaving] = useState(false);
  const [loading, setloading] = useState(true);

  const [captcha, setCaptcha] = useState(appFxs.randomCharsFormInts(5));
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const [listCtries, setListCtries] = useState<ICountry[]>(countries);

  const [formData, setFormData] = useState({ ...emptyRecord });
  const ontitleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const eValue = e.target.value;
    const oSex = Number(eValue) === 0 ? 0 : 1;
    setFormData({ ...formData, Title: eValue, Sex: oSex.toString() });
  };
  const onCtryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, Country: e.target.value });
  };

  const phoneCode = useMemo(() => {
    const selCtry = listCtries.find((o) => o.code === formData.Country);
    return selCtry ? selCtry.dial_code : "+250";
  }, [formData.Country]);
  const isCaptchaValid = useMemo(() => {
    return captcha === formData.SecureCode;
  }, [captcha, formData.SecureCode]);

  const inputRef = useMask({
    mask: `${phoneCode.substring(1)} ___-___-___`,
    replacement: { _: /\d/ },
  });

  const submitRequestContact = async () => {
    console.log("formData", formData);
    const errors: IFormError = {};

    //Email
    if (!formData.Email || formData.Email.length === 0) {
      errors.Email = languages.field_required;
    } else if (!appFxs.isEmailValid(formData.Email)) {
      errors.Email = languages.email_seems_not_valid;
    }

    //MobilePhone
    if (!formData.MobilePhone || formData.MobilePhone.length === 0) {
      errors.MobilePhone = languages.field_required;
    } else if (
      formData.MobilePhone.replaceAll("-", "").replaceAll(" ", "").length !== 12
    ) {
      errors.MobilePhone = languages.phone_error_msg;
    }

    //FirstName
    if (!formData.FirstName || formData.FirstName.length === 0) {
      errors.FirstName = languages.field_required;
    } else if (formData.FirstName.length < 3) {
      errors.FirstName = languages.min_length_error_3;
    }

    //LastName
    if (!formData.LastName || formData.LastName.length === 0) {
      errors.LastName = languages.field_required;
    } else if (formData.LastName.length < 3) {
      errors.LastName = languages.min_length_error_3;
    }

    //City
    if (!formData.City || formData.City.length === 0) {
      errors.City = languages.field_required;
    } else if (formData.City.length < 3) {
      errors.City = languages.min_length_error_3;
    }

    //Subject
    if (!formData.Subject || formData.Subject.length === 0) {
      errors.Subject = languages.field_required;
    } else if (formData.Subject.length < 10) {
      errors.Subject = languages.min_length_error_10;
    }

    //Subject
    if (!formData.Message || formData.Message.length === 0) {
      errors.Message = languages.field_required;
    } else if (formData.Message.length < 15) {
      errors.Message = languages.min_length_error_15;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const mobPhone = formData.MobilePhone.replaceAll(" ", "").replaceAll(
      "-",
      ""
    );

    const oOper = await NetServices.requestPost(
      "general/savecontactuser",
      {
        userRequest: {
          ...formData,
          MobilePhone: mobPhone,
        },
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    await appFxs.showAlert(languages.success, languages.email_sent_success);

    navigate("/resume/aboutme");
  };

  const populateCountries = async () => {
    const oOper = await NetServices.requestGet("general/getlistcountries");

    if (!oOper.bReturn) {
      navigate("/resume/aboutme");
      return;
    }

    const fCtries = JSON.parse(oOper.listOfCountriesTxt!)
      .Countries as ICountry[];

    setListCtries(fCtries);
    setloading(false);
  };

  useEffect(() => {
    setCaptcha(appFxs.randomCharsFormInts(5));
    populateCountries();
  }, []);

  if (loading) return <LoadingPage />;

  return (
    <section id="contact" className="bg-white">
      <div className="contact">
        <div className="content">
          <div className="block-content mb-100 t-center">
            <div className="row">
              <div className="col-md-12">
                <div className="main-title">
                  <h1 className="mb-20">{languages.contact}</h1>
                  <h5 className="uppercase">{languages.say_hello}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100 t-center">
            <div className="row">
              <div className="col-md-12  ">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">{languages.map_location}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="block-map">
                  <div id="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3353.138474134239!2d30.060874289033332!3d-1.9209565870108782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca319793f1033%3A0x2674a83637cf6b7a!2sGisozi%20Green%20Housing%20Aparthotel!5e0!3m2!1sen!2srw!4v1719483145423!5m2!1sen!2srw" 
                  allowFullScreen={true} 
                  loading="lazy" 
                  referrerPolicy="no-referrer-when-downgrade"
                  title="WiseObjects"
                  style={{
                    height: "100%"
                  }}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100 t-center">
            <div className="row">
              <div className="col-md-12  ">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">{languages.personal_info}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="block-info ">
                <div className="col-md-4">
                  <div className="info">
                    <div className="ico mb-20">
                      <i className="icon-location-7"></i>
                    </div>
                    <h5 className="mb-20">{languages.drop_on_in}</h5>
                    <p>
                      Café de Gisozi
                      <br /> KG 753 Street
                      <br /> 3226 Kigali, Rwanda
                    </p>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="info">
                    <div className="ico mb-20">
                      <i className="icon-mobile-6"></i>
                    </div>
                    <h5 className="mb-20">{languages.give_me_call}</h5>
                    <p>
                      {languages.office} : +250 788 307 310
                      <br />
                      Fax : - <br />
                      Mobile : +250 788 307 310
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="info">
                    <div className="ico mb-20">
                      <i className="icon-paper-plane-3"></i>
                    </div>
                    <h5 className=" mb-20">{languages.lets_connect}</h5>
                    <p>
                      Email:
                      <a href="mailto:jkayihura@msn.com">jkayihura@msn.com</a>
                      <br /> Twitter :
                      <a
                        href="https://twitter.com/jkayihura"
                        target="_blank"
                        rel="noreferrer"
                      >
                        @jkayihura
                      </a>
                      <br /> Skype :
                      <a href={`skype:jkayihura?chat`}>jkayihura</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100 ">
            <div className="row">
              <div className="col-md-12 t-center">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">{languages.reach_me_here}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="block-reach">
                  <form className="contact-form" name="frmReqInfo">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label htmlFor="Title">{languages.title}</label>
                        <select
                          name="Title"
                          id="Title"
                          className="form-control"
                          value={formData.Title}
                          onChange={ontitleChange}
                        >
                          {senderTitles.map((o, idx) => (
                            <option key={idx} value={idx}>
                              {o}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="LanguageType">
                          {languages.language}
                        </label>
                        <select
                          name="LanguageType"
                          id="LanguageType"
                          className="form-control"
                          value={formData.LanguageType}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              LanguageType: e.target.value,
                            })
                          }
                        >
                          {formLanguages.map((o, idx) => (
                            <option key={idx} value={idx}>
                              {o}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label htmlFor="Sex">{languages.sex}</label>
                        <select
                          name="Sex"
                          id="Sex"
                          className="form-control"
                          value={formData.Sex}
                          disabled={true}
                        >
                          {formsexes.map((o, idx) => (
                            <option key={idx} value={idx}>
                              {o}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="Country">
                          {languages.country_origin}
                        </label>
                        <select
                          name="Country"
                          id="Country"
                          className="form-control"
                          value={formData.Country}
                          onChange={onCtryChange}
                        >
                          {listCtries.map((o, idx) => (
                            <option key={idx} value={o.code}>
                              {o.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-md-6 form-group ${
                          formErrors.Email && "has-error"
                        }`}
                      >
                        <label htmlFor="Email">{languages.email}</label>
                        <input
                          type="email"
                          placeholder={`(${languages.email_sample.toLowerCase()})`}
                          className="form-control"
                          id="Email"
                          name="Email"
                          maxLength={35}
                          value={formData.Email}
                          onChange={(e) =>
                            setFormData({ ...formData, Email: e.target.value })
                          }
                        />
                        {formErrors.Email && (
                          <div className="text-sm text-danger font-italic my-1">
                            {formErrors.Email}
                          </div>
                        )}
                      </div>
                      <div
                        className={`col-md-6 form-group ${
                          formErrors.MobilePhone && "has-error"
                        }`}
                      >
                        <label htmlFor="MobilePhone">
                          {languages.mobile_phone}-{languages.dial_code}: &nbsp;
                          {phoneCode}
                        </label>
                        <input
                          ref={inputRef}
                          type="text"
                          className="form-control"
                          id="MobilePhone"
                          name="MobilePhone"
                          value={formData.MobilePhone}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              MobilePhone: e.target.value,
                            })
                          }
                        />
                        {formErrors.MobilePhone && (
                          <div className="text-sm text-danger font-italic my-1">
                            {formErrors.MobilePhone}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-md-6 form-group ${
                          formErrors.FirstName && "has-error"
                        }`}
                      >
                        <label htmlFor="FirstName">
                          {languages.first_name}
                        </label>
                        <input
                          type="text"
                          placeholder={`(${languages.first_name.toLowerCase()})`}
                          className="form-control"
                          id="FirstName"
                          name="FirstName"
                          maxLength={15}
                          value={formData.FirstName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              FirstName: e.target.value,
                            })
                          }
                        />
                        {formErrors.FirstName && (
                          <div className="text-sm text-danger font-italic my-1">
                            {formErrors.FirstName}
                          </div>
                        )}
                      </div>
                      <div
                        className={`col-md-6 form-group ${
                          formErrors.LastName && "has-error"
                        }`}
                      >
                        <label htmlFor="LastName">{languages.last_name}</label>
                        <input
                          type="text"
                          placeholder={`(${languages.last_name.toLowerCase()})`}
                          className="form-control"
                          id="LastName"
                          name="LastName"
                          maxLength={15}
                          value={formData.LastName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              LastName: e.target.value,
                            })
                          }
                        />
                        {formErrors.LastName && (
                          <div className="text-sm text-danger font-italic my-1">
                            {formErrors.LastName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-md-6 form-group ${
                          formErrors.City && "has-error"
                        }`}
                      >
                        <label htmlFor="City">{languages.city}</label>
                        <input
                          type="text"
                          placeholder={`(${languages.city.toLowerCase()})`}
                          className="form-control"
                          id="City"
                          name="City"
                          maxLength={15}
                          value={formData.City}
                          onChange={(e) =>
                            setFormData({ ...formData, City: e.target.value })
                          }
                        />
                        {formErrors.City && (
                          <div className="text-sm text-danger font-italic my-1">
                            {formErrors.City}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 form-group">
                        <label htmlFor="Interest">{languages.interest}</label>
                        <select
                          name="Interest"
                          id="Interest"
                          className="form-control"
                          value={formData.Interest}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              Interest: e.target.value,
                            })
                          }
                        >
                          <option value="0">{languages.website}</option>
                          <option value="1">{languages.web_application}</option>
                          <option value="2">{languages.desk_appli_all}</option>
                          <option value="3">{languages.mobile_app_all}</option>
                          <option value="4">{languages.hosting_asp}</option>
                          <option value="5">{languages.hosting_linux}</option>
                          <option value="6">{languages.hosting_node}</option>
                          <option value="7">{languages.bus_intell_pbi}</option>
                          <option value="8">{languages.bus_intell_tbl}</option>
                          <option value="9">
                            {languages.bus_intell_python}
                          </option>
                          <option value="10">
                            {languages.training_mentorship}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      className={`form-group ${
                        formErrors.Subject && "has-error"
                      }`}
                    >
                      <label htmlFor="Subject">{languages.subject}</label>
                      <input
                        type="text"
                        placeholder={`(${languages.subject.toLowerCase()})`}
                        className="form-control"
                        id="Subject"
                        name="Subject"
                        maxLength={150}
                        value={formData.Subject}
                        onChange={(e) =>
                          setFormData({ ...formData, Subject: e.target.value })
                        }
                      />
                      {formErrors.Subject && (
                        <div className="text-sm text-danger font-italic my-1">
                          {formErrors.Subject}
                        </div>
                      )}
                    </div>
                    <div
                      className={`form-group ${
                        formErrors.Message && "has-error"
                      }`}
                    >
                      <label htmlFor="Message">{languages.message}</label>
                      <textarea
                        name="Message"
                        placeholder="Message"
                        id="Message"
                        className="form-control"
                        rows={7}
                        maxLength={2500}
                        value={formData.Message}
                        onChange={(e) =>
                          setFormData({ ...formData, Message: e.target.value })
                        }
                      ></textarea>

                      {formErrors.Message && (
                        <div className="text-sm text-danger font-italic my-1">
                          {formErrors.Message}
                        </div>
                      )}
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label form-text">
                        <input
                          type="checkbox"
                          id="CanReceiveMonthlyEmail"
                          name="CanReceiveMonthlyEmail"
                          className="form-check-input"
                          checked={formData.CanReceiveMonthlyEmail}
                          onChange={(_) =>
                            setFormData({
                              ...formData,
                              CanReceiveMonthlyEmail:
                                !formData.CanReceiveMonthlyEmail,
                            })
                          }
                        />
                        <span>{languages.subscribe_msg}</span>
                      </label>
                    </div>
                    <div className="recaptcha-box">
                      <div className="cover">
                        <input
                          type="text"
                          id="SecureCode"
                          name="SecureCode"
                          maxLength={5}
                          minLength={5}
                          className="number"
                          style={{
                            height: "auto",
                            marginBottom: 0,
                          }}
                          value={formData.SecureCode}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              SecureCode: e.target.value,
                            })
                          }
                        />
                        <div className="cap-photo">
                          {captcha}
                          <div className="v-hide"></div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={() => submitRequestContact()}
                        disabled={saving || !isCaptchaValid}
                      >
                        <span>
                          {saving
                            ? languages.submitting_data_server
                            : languages.send}
                        </span>
                        &nbsp;
                        {saving && <i className="fas fa-spinner fa-pulse"></i>}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content t-center">
            <div className="row">
              <div className="col-md-12">
                <span className="page-number emph-1">- 5/5 -</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
