/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import getLang from "src/utils/languages";

import servProgr from "src/assets/images/service_programming.jpg";

const languages = getLang();
const MyResumePage = () => {
  document.title = `${languages.my_resume} | ${languages.pers_resume_serv}`;
  return (
    <section id="resume" className="bg-white t-center">
      <div className="resume">
        <div className="content">
          <div className="block-content mb-100">
            <div className="row">
              <div className="col-md-12  ">
                <div className="main-title">
                  <h1 className="mb-20">{languages.resume}</h1>
                  <h5 className="uppercase">{languages.cv_long}</h5> 
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100  pb-10">
            <div className="row">
              <div className="col-md-12  ">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">{languages.experience}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12  ">
                <div className="timeline">
                  <div className="timeline-inner">
                    <div className="name">
                      <span className="date">
                        {languages.jan2016}
                        <em>{languages.current}</em>
                      </span>
                      <h4>{languages.lead_web_mob_dev}</h4>
                      <h4>{languages.lead_bus_data_anal}</h4>
                      <span className="date">
                        Wise Business Tools Ltd (Rwanda)
                      </span>
                    </div>
                    <div className="detail">
                      <p>{languages.job_1_descipt_a}</p>
                      <br />
                      <p>{languages.job_1_descipt_b}</p>
                    </div>
                  </div>
                  <div className="timeline-inner ">
                    <div className="name switched">
                      <span className="date">{languages.december_2012}</span>
                      <h4>{languages.soft_anal_dev}</h4>
                      <span className="date">WebExpert Ltd (Rwanda)</span>
                    </div>
                    <div className="detail">
                      <p>{languages.job_2_descipt_a}</p>
                      <br />
                      <p>{languages.job_2_descipt_b}</p>
                    </div>
                  </div>
                  <div className="timeline-inner">
                    <div className="name">
                      <span className="date">{languages.september_2005}</span>
                      <h4>{languages.head_it_dept}</h4>
                      <h4>{languages.software_developer}</h4>
                      <h4>{languages.system_analyst}</h4>
                      <span className="date">
                        CORAR Ltd{" "}
                        <span className="former-company">
                          {languages.current.toLowerCase()}
                          Sanlam Ltd
                        </span>
                      </span>
                    </div>
                    <div className="detail">
                      <p>{languages.job_corar_descript_1}</p>
                      <br />
                      <p>{languages.job_corar_descript_2}</p>
                    </div>
                  </div>
                  <div className="timeline-inner">
                    <div className="name switched">
                      <span className="date">{languages.january_2002}</span>
                      <h4>{languages.head_it_dept}</h4>
                      <h4>{languages.software_developer}</h4>
                      <h4>{languages.system_analyst}</h4>
                      <span className="date">
                        COGEAR S.A{" "}
                        <span className="former-company">
                          {languages.current.toLowerCase()}
                          Prime Ltd
                        </span>
                      </span>
                    </div>
                    <div className="detail">
                      <p>{languages.job_cogear_descript}</p>
                    </div>
                  </div>
                  <div className="timeline-inner">
                    <div className="name switched">
                      <span className="date">{languages.january_2001}</span>
                      <h4>{languages.head_tech_dept}</h4>
                      <h4>{languages.exchange_messaging_specialist}</h4>
                      <span className="date">AXIOM Computers SA</span>
                    </div>
                    <div className="detail">
                      <p>{languages.job_axiom_descript}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100 pb-10">
            <div className="row">
              <div className="col-md-12  ">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">Education</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12  ">
                <div className="timeline">
                  <div className="timeline-inner">
                    <div className="name">
                      <span className="date">{languages.december_2020}</span>
                      <h4>
                        {languages.certification} –
                        <em>{languages.business_intelligence}</em>
                      </h4>
                      <span className="date">
                        {languages.coursera_certification}
                      </span>
                    </div>
                    <div className="detail">
                      <ul>
                        <li>Data Modeling</li>
                        <li>Applied Statistics</li>
                        <li>
                          <a
                            href="https://www.tableau.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Tableau
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://powerbi.microsoft.com/en-us/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Power BI
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://docs.microsoft.com/en-us/analysis-services/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SQL Server - Analysis Services
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://powerquery.microsoft.com/en-us/excel/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Miscrosoft Excel - Power Query
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://docs.microsoft.com/en-us/office/vba/excel/Concepts/about-the-powerpivot-model-object-in-excel"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Miscrosoft Excel - Power Pivot
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://docs.microsoft.com/en-us/powerquery-m/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Power Query M
                          </a>{" "}
                          language
                        </li>
                        <li>
                          <a
                            href="https://docs.microsoft.com/en-us/dax/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            DAX
                          </a>{" "}
                          language
                        </li>
                        <li>
                          <a
                            href="https://www.probytes.net/blog/python-for-business-intelligence/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Python for Business Intelligence
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="timeline-inner ">
                    <div className="name switched">
                      <span className="date">{languages.july_2000}</span>
                      <h4>
                        {languages.bachelor_degree} –
                        <em>{languages.info_gestion}</em>
                      </h4>
                      <span className="date">
                        {languages.analyse_programming}
                      </span>
                      <br />
                      <span className="date">
                        I.S.I.G -{" "}
                        <em>Institut Supérieur d'Informatique et de Gestion</em>
                      </span>
                    </div>
                    <div className="detail">
                      <ul>
                        <li>Applied Business</li>
                        <li>Descriptive Statisitcs</li>
                        <li>Social Business</li>
                        <li>MERISE</li>
                        <li>Programming Languages</li>
                        <li>Software Development</li>
                        <li>Ms Office VBA</li>
                        <li>Databases</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100  pb-30">
            <div className="row">
              <div className="col-md-12  ">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">skills</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12  ">
                <div className="listing-large mt-40">
                  <a
                    className="uppercase emph-1 btn-1"
                    style={{ cursor: "pointer" }}
                  >
                    Download my cv
                  </a>
                  <a
                    className="uppercase emph-1 btn-2"
                    style={{ cursor: "pointer" }}
                  >
                    Print My resume
                  </a>
                  <div className="listing-large-inner">
                    <div className="listing-event">
                      <ul className="data left clearfix">
                        <li>
                          <h5>Front-End</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>Asp.Net Framework</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>Asp.Net Core</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>AngularJS</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>Angular 2+</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>Flutter</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>React Native</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>C#</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>Java</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>PHP</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>Swift</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                        <li>
                          <h5>Dart</h5>
                          <div className="rating">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                            <span className="transparent"></span>
                          </div>
                        </li>
                      </ul>
                      <ul className="data right clearfix">
                        <li>
                          <h5>{languages.assets}</h5>
                          <p className="emph-3">{languages.assets_list}</p>
                        </li>
                        <li>
                          <h5>Languages</h5>
                          <ul>
                            <li className="emph-1">
                              English{" "}
                              <span className="emph-4">(Normal Speaking)</span>
                            </li>
                            <li className="emph-1">
                              French{" "}
                              <span className="emph-4">(Fluent Speaking)</span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <h5>Hobbies &amp; Interests</h5>
                          <ul className="hb-list">
                            <li>
                              <span>
                                <i className="icon-music-4"></i>
                              </span>
                              <h6>Music</h6>
                            </li>
                            <li>
                              <span>
                                <i className="icon-globe-6"></i>
                              </span>
                              <h6>Travel</h6>
                            </li>
                            <li>
                              <span>
                                <i className="icon-food-1"></i>
                              </span>
                              <h6>Cooking</h6>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-50  pb-30">
            <div className="row">
              <div className="col-md-12">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">Services</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <img alt="" src={servProgr} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="services mt-30">
                  <div className="block-service clearfix over-ride-serv">
                    <div className="col-sm-6 text-center">
                      <div className="ico mb-15">
                        <i className="far fa-thumbs-up"></i>
                      </div>
                      <div className="det">
                        <h5 className="mb-10">{languages.great_support}</h5>
                        <p>{languages.great_support_msg}</p>
                      </div>
                    </div>
                    <div className="col-sm-6 text-center">
                      <div className="ico mb-15">
                        <i className="fas fa-server"></i>
                      </div>
                      <div className="det">
                        <h5 className="mb-10">{languages.website_hosting}</h5>
                        <p>{languages.website_hosting_msg}</p>
                      </div>
                    </div>
                    <div className="col-sm-6 text-center">
                      <div className="ico mb-15">
                        <i className="fas fa-laptop-house"></i>
                      </div>
                      <div className="det">
                        <h5 className="mb-10">
                          {languages.office_applications}
                        </h5>
                        <p>{languages.office_applications_msg}</p>
                      </div>
                    </div>
                    <div className="col-sm-6 text-center">
                      <div className="ico mb-15">
                        <i className="fas fa-globe"></i>
                      </div>
                      <div className="det">
                        <h5 className="mb-10">{languages.website_design}</h5>
                        <p>{languages.website_design_msg}</p>
                      </div>
                    </div>
                    <div className="col-sm-6 text-center">
                      <div className="ico mb-15">
                        <i className="fas fa-laptop-code"></i>
                      </div>
                      <div className="det">
                        <h5 className="mb-10">{languages.web_desk_mob_app}</h5>
                        <p>{languages.web_desk_mob_app_msg}</p>
                      </div>
                    </div>
                    <div className="col-sm-6 text-center">
                      <div className="ico mb-15">
                        <i className="fas fa-database"></i>
                      </div>
                      <div className="det">
                        <h5 className="mb-10">
                          {languages.business_intel_apps}
                        </h5>
                        <p>{languages.business_intel_apps_msg}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100 " ng-if="false">
            <div className="row">
              <div className="col-md-12  ">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">testimonials</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-12">
                <div className="col-md-10 col-md-offset-1">
                  <div className="testi clearfix ">
                    <ul id="testimonial-carousel">
                      <li>
                        <div className="block-quote t-left">
                          <blockquote>
                            “Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse.”
                          </blockquote>
                          <h6 className="block-profile mt-20">
                            Carlose Anderson <span>Mutation Media CEO</span>
                          </h6>
                        </div>
                        <div className="block-img">
                          <img alt="" src="/assets/images/testimonial/1.png" />
                        </div>
                      </li>
                      <li>
                        <div className="block-quote t-left">
                          <blockquote>
                            “Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse.”
                          </blockquote>
                          <h6 className="block-profile mt-20">
                            Jonathan Anderson<span> Mutation Media CEO</span>
                          </h6>
                        </div>
                        <div className="block-img">
                          <img alt="" src="/assets/images/testimonial/2.png" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="block-content">
            <div className="row">
              <div className="col-md-12">
                <span className="page-number emph-1">- 2/5 -</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyResumePage