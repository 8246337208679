export enum DateOperation {
    Day,
    Month,
    Year,
  }

  export type HeaderType = {
    "user-access-key": string;
    lang: string;
    "company-code": string;
    "client-operation-date": string;
    "year-exercise-app": string;
    "default-sms-account": string;
    "current-devise-val": string;
    "current-branch-val": string;
    "user-email-value": string;
    "user-password-value": string;
    "app-type": string;
  };

  export interface IPassworderChecker {
    capLetters: {
      len: number,
      status: 'poor'|'medium'|'high',
    },
    smLetters: {
      len: number,
      status: 'poor'|'medium'|'high',
    },
    specChars: {
      len: number,
      status: 'poor'|'medium'|'high',
    },
    numChars: {
      len: number,
      status: 'poor'|'medium'|'high',
    },
    lenChars: {
      len: number,
      status: 'poor'|'medium'|'high',
    },
    percentVal: number,
  }
  


  export interface ReturnInfo {
    bReturn: boolean,
    msgTitle: string,
    msgBody: string,
  }


  
export interface IPexelsImageSrc {
    landscape?: string;
    large?: string;
    large2x?: string;
    medium?: string;
    original?: string;
    portrait?: string;
    small?: string;
    tiny?: string;
  }
  export interface IPexelsImage {
    alt: string;
    avg_color: string;
    height: number;
    id: number;
    liked: boolean;
    photographer: string;
    photographer_id: number;
    photographer_url: string;
    url: string;
    width: number;
    src: IPexelsImageSrc;
  }
  export interface IPexelsImageRequest {
    next_page: string;
    page: number;
    per_page: number;
    photos: IPexelsImage[];
  }

  export interface IUserContactRequest {
      IdUser:number,
      Email: string,
      FirstName: string,
      LastName: string,
      Sex: string,
      Title: string,
      LanguageType: string,
      Interest: string,
      MobilePhone: string,
      Subject: string,
      Message: string,
      CanReceiveMonthlyEmail: boolean,
      SecureCode: string,
      Country: string,
      City: string,
      DateRegistration: Date,
    }

    export interface IFormError {
      Email?: string;
      FirstName?: string;
      LastName?: string;
      Sex?: string;
      Title?: string;
      LanguageType?: string;
      Interest?: string;
      MobilePhone?: string;
      Subject?: string;
      Message?: string;
      SecureCode?: string;
      Country?: string;
      City?: string;
    }

    export interface ICountry {
      code: string;
      dial_code: string;
      name: string;
    }