import getLang from "src/utils/languages";
import colombWebsite from "src/assets/images/apps/lacolombiere_web.png";
import colombWebApp from "src/assets/images/apps/lacolombiere_managt_app.png";
import colombBlog from "src/assets/images/apps/lacolombiere_blog.png";
import colombAlumni from "src/assets/images/apps/lacolombiere_alumni.png";
import archivixImg from "src/assets/images/apps/archivix_img.png";
import congodoctsImg from "src/assets/images/apps/congodocts_web.png";
import ascenWebImg from "src/assets/images/apps/ascen_website.png";
import stockViewerImg from "src/assets/images/apps/stockviewer.png";

import villeDeBanguiWeb from "src/assets/images/apps/ville_bangui_web.png";
import villeDeBanguiPortail from "src/assets/images/apps/ville_bangui_portail.png";
import villeDeBanguiAdmin from "src/assets/images/apps/ville_bangui_admin.png";
import villeDeBanguiArch from "src/assets/images/apps/ville_bangui_archives.png";

import falconInsurImage from "src/assets/images/apps/falcon_website.png";
import innovationStratImage from "src/assets/images/apps/innov_strategy_web.png";
import fis250InsurancesImage from "src/assets/images/apps/250_insurances_app.png";

import wawaShopImage from "src/assets/images/apps/wawashop_mobile_app.png";

import tripAfricaBookingImage from "src/assets/images/apps/trip_africa_booking.png";
import tripAfricaManagtImage from "src/assets/images/apps/trip_africa_mgt.png";
import tripAfricaRailsImage from "src/assets/images/apps/trip_africa_rails.png";

import muaAgentsImage from "src/assets/images/apps/mua_agents.png";
import sengaAppImage from "src/assets/images/apps/senga_web_mobile.png";
import mobiAssurImage from "src/assets/images/apps/mobi_assur_app.png";
import motoRateImage from "src/assets/images/apps/motorate_app.png";

import brokerixImage from "src/assets/images/apps/brokerix_app.png";
import cimImage from "src/assets/images/apps/corar_insurance_manager.png";
import wimImage from "src/assets/images/apps/wise_insurance_manager.png";

const languages = getLang();

export const mimeTypes = [
  "image/jpeg,image/png",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
];

export interface IAppRecord {
  id: number;
  name: string;
  year: number;
  imagePath: string;
  category:
    | "wordpress"
    | "website"
    | "webapp"
    | "mobile"
    | "webmobile"
    | "mobiledesktop"
    | "desktop";
  description: string;
  framework:
    | "ReactJs"
    | "AngularJs"
    | "Angular 2+"
    | "VueJs"
    | ".Net Framework"
    | "Core"
    | undefined;
  database?: "Firebase" | "MySql" | "Sql Server" | "Mongo DB" | undefined;
  apiFramework?:
    | "NodeJs"
    | ".Net Framework Web Api"
    | "Core Web Api"
    | "Firebase"
    | undefined;
  websiteLink?: string;
  mobileFramework:
    | "iOS/Android"
    | "iOS"
    | "Android"
    | "Flutter"
    | "React Native"
    | undefined;
  iosLink?: string;
  androidLink?: string;
}

export const listOfAppWorks: IAppRecord[] = [
  {
    id: 0,
    name: "Archivix",
    imagePath: archivixImg,
    year: 2024,
    category: "webmobile",
    description: languages.archivixDescr,
    framework: "ReactJs",
    mobileFramework: "React Native",
    apiFramework: "NodeJs",
    websiteLink: "https://archivix.net",
    database: "Mongo DB",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 1,
    name: "La Colombière School",
    imagePath: colombWebsite,
    year: 2023,
    category: "website",
    description: languages.colombDescr,
    framework: "ReactJs",
    mobileFramework: undefined,
    apiFramework: "NodeJs",
    websiteLink: "https://lacolombiere.ac.rw",
    database: "Mongo DB",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 2,
    name: "La Colombière - Management",
    year: 2023,
    imagePath: colombWebApp,
    category: "webapp",
    description: languages.colombMgtDescr,
    framework: "ReactJs",
    mobileFramework: undefined,
    apiFramework: "NodeJs",
    websiteLink: "https://managt.lacolombiere.ac.rw",
    database: "Mongo DB",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 3,
    name: "La Colombière - Blog",
    year: 2023,
    imagePath: colombBlog,
    category: "wordpress",
    description: languages.colombMgtDescr,
    framework: undefined,
    mobileFramework: undefined,
    apiFramework: undefined,
    websiteLink: "https://blog.lacolombiere.ac.rw",
    database: undefined,
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 4,
    name: "La Colombière - Alumni",
    year: 2023,
    imagePath: colombAlumni,
    category: "webapp",
    description: languages.colombAlumniDescr,
    framework: "ReactJs",
    mobileFramework: "React Native",
    apiFramework: "NodeJs",
    websiteLink: "https://alumni.lacolombiere.ac.rw",
    database: "Mongo DB",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 5,
    name: "Congo Documents",
    year: 2023,
    imagePath: congodoctsImg,
    category: "webapp",
    description: languages.congoDocsDescr,
    framework: "VueJs",
    mobileFramework: undefined,
    apiFramework: "NodeJs",
    websiteLink: "https://congodocuments.net",
    database: "Mongo DB",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 6,
    name: "250Insurances",
    year: 2023,
    imagePath: fis250InsurancesImage,
    category: "webmobile",
    description: languages.fis250InsurWeb,
    framework: "ReactJs",
    mobileFramework: "Flutter",
    apiFramework: ".Net Framework Web Api",
    websiteLink: "https://250insurances.fis.rw",
    database: "Sql Server",
    iosLink: undefined,
    androidLink:
      "https://play.google.com/store/apps/details?id=com.wiseobjects.fis250insurances",
  },
  {
    id: 6,
    name: "StockViewer",
    year: 2023,
    imagePath: stockViewerImg,
    category: "mobiledesktop",
    description: languages.stocViewerDescr,
    framework: undefined,
    mobileFramework: "Flutter",
    apiFramework: ".Net Framework Web Api",
    websiteLink: "https://stockviewer.wiseobjects.com",
    database: "Sql Server",
    iosLink: "https://apps.apple.com/us/app/wise-stockviewer/id6452755062",
    androidLink: undefined,
  },
  {
    id: 7,
    name: "ASCEN",
    year: 2022,
    imagePath: ascenWebImg,
    category: "wordpress",
    description: languages.ascenDescr,
    framework: undefined,
    mobileFramework: undefined,
    apiFramework: undefined,
    websiteLink: "https://afriscen.org",
    database: undefined,
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 8,
    name: "Ville de Bangui",
    year: 2021,
    imagePath: villeDeBanguiWeb,
    category: "wordpress",
    description: languages.villedeBanguiWeb,
    framework: undefined,
    mobileFramework: undefined,
    apiFramework: "NodeJs",
    websiteLink: "https://villedebangui.cf",
    database: "Mongo DB",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 9,
    name: "Ville de Bangui - Archives",
    year: 2021,
    imagePath: villeDeBanguiArch,
    category: "webapp",
    description: languages.villedeBanguiArchives,
    framework: "ReactJs",
    mobileFramework: undefined,
    apiFramework: "NodeJs",
    websiteLink: "https://archives.villedebangui.cf",
    database: "Mongo DB",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 10,
    name: "Ville de Bangui - Admin",
    year: 2021,
    imagePath: villeDeBanguiAdmin,
    category: "webapp",
    description: languages.villedeBanguiManagt,
    framework: "ReactJs",
    mobileFramework: undefined,
    apiFramework: "NodeJs",
    websiteLink: "https://admin.villedebangui.cf",
    database: "Mongo DB",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 11,
    name: "Ville de Bangui - Portail",
    year: 2021,
    imagePath: villeDeBanguiPortail,
    category: "webapp",
    description: languages.villedeBanguiPortal,
    framework: "ReactJs",
    mobileFramework: undefined,
    apiFramework: "NodeJs",
    websiteLink: "https://portail.villedebangui.cf",
    database: "Mongo DB",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 11,
    name: "Innovation and ",
    year: 2021,
    imagePath: innovationStratImage,
    category: "website",
    description: languages.innovationStratWeb,
    framework: "ReactJs",
    mobileFramework: undefined,
    apiFramework: undefined,
    websiteLink: "https://innovationstrategy.wiseobjects.com/",
    database: undefined,
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 12,
    name: "Falcon Insurance Services (FIS)",
    year: 2020,
    imagePath: falconInsurImage,
    category: "website",
    description: languages.falconInsurWeb,
    framework: "ReactJs",
    mobileFramework: undefined,
    apiFramework: ".Net Framework Web Api",
    websiteLink: "https://fis.rw",
    database: "Sql Server",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 13,
    name: "Wawa Shop",
    year: 2020,
    imagePath: wawaShopImage,
    category: "mobile",
    description: languages.wawashopWeb,
    framework: undefined,
    mobileFramework: "Android",
    apiFramework: ".Net Framework Web Api",
    websiteLink: "https://wawashop.wiseobjects.com",
    database: "Sql Server",
    iosLink: undefined,
    androidLink:
      "https://play.google.com/store/apps/details?id=com.wiseobjects.wawashop",
  },
  {
    id: 14,
    name: "TripAfrica Booking",
    year: 2019,
    imagePath: tripAfricaBookingImage,
    category: "webmobile",
    description: languages.tripAfricaBookDescr,
    framework: "Angular 2+",
    mobileFramework: "iOS/Android",
    apiFramework: "Firebase",
    websiteLink: "https://tripafrica.wiseobjects.com",
    database: "Firebase",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 15,
    name: "TripAfrica Rails",
    year: 2019,
    imagePath: tripAfricaRailsImage,
    category: "webapp",
    description: languages.tripAfricaRailsDescr,
    framework: "Angular 2+",
    mobileFramework: undefined,
    apiFramework: "Firebase",
    websiteLink: "https://tripafricarails.wiseobjects.com",
    database: "Firebase",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 16,
    name: "TripAfrica Management",
    year: 2019,
    imagePath: tripAfricaManagtImage,
    category: "webapp",
    description: languages.tripAfricaManagtDescr,
    framework: "Angular 2+",
    mobileFramework: undefined,
    apiFramework: "Firebase",
    websiteLink: "https://tripafricaadmin.wiseobjects.com",
    database: "Firebase",
    iosLink: undefined,
    androidLink: undefined,
  },
  {
    id: 17,
    name: "MUA Agents",
    year: 2019,
    imagePath: muaAgentsImage,
    category: "webapp",
    description: languages.muaAgentsDescr,
    framework: "Angular 2+",
    mobileFramework: undefined,
    apiFramework: ".Net Framework Web Api",
    websiteLink: "mua-agents.wiseobjects.com",
    database: "Sql Server",
    iosLink: undefined,
    androidLink: undefined,
  },

  {
    id: 18,
    name: "Senga",
    year: 2018,
    imagePath: sengaAppImage,
    category: "webmobile",
    description: languages.sengaAgentsDescr,
    framework: "Angular 2+",
    mobileFramework: "Android",
    apiFramework: ".Net Framework Web Api",
    websiteLink: "https://senga.wiseobjects.com",
    database: "Sql Server",
    iosLink: undefined,
    androidLink:
      "https://play.google.com/store/apps/details?id=com.wiseobjects.senga&hl=en_US&gl=US",
  },

  {
    id: 19,
    name: "Mobi Assur",
    year: 2018,
    imagePath: mobiAssurImage,
    category: "webmobile",
    description: languages.mobiAssurDescr,
    framework: "AngularJs",
    mobileFramework: "Android",
    apiFramework: ".Net Framework Web Api",
    websiteLink: "https://x-assur.wiseobjects.com",
    database: "Sql Server",
    iosLink: undefined,
    androidLink: undefined,
  },

  {
    id: 20,
    name: "Motorate",
    year: 2017,
    imagePath: motoRateImage,
    category: "webmobile",
    description: languages.motorateDescr,
    framework: "AngularJs",
    mobileFramework: "iOS/Android",
    apiFramework: ".Net Framework Web Api",
    websiteLink: "https://motorate.wiseobjects.com",
    database: "Sql Server",
    iosLink: undefined,
    androidLink: undefined,
  },

  {
    id: 20,
    name: "Brokerix",
    year: 2015,
    imagePath: brokerixImage,
    category: "webapp",
    description: languages.brokerixDescr,
    framework: "AngularJs",
    mobileFramework: undefined,
    apiFramework: ".Net Framework Web Api",
    websiteLink: "https://brokerix.wiseobjects.com",
    database: "Sql Server",
    iosLink: undefined,
    androidLink: undefined,
  },
  

  {
    id: 21,
    name: "CIM - Corar Insurance Manager",
    year: 2008,
    imagePath: cimImage,
    category: "desktop",
    description: languages.cimDescrDescr,
    framework: ".Net Framework",
    mobileFramework: undefined,
    apiFramework: ".Net Framework Web Api",
    websiteLink: undefined,
    database: "Sql Server",
    iosLink: undefined,
    androidLink: undefined,
  },

  {
    id: 21,
    name: "WIM - Wise Insurance Manager",
    year: 2005,
    imagePath: wimImage,
    category: "desktop",
    description: languages.wimDescrDescr,
    framework: ".Net Framework",
    mobileFramework: undefined,
    apiFramework: ".Net Framework Web Api",
    websiteLink: undefined,
    database: "Sql Server",
    iosLink: undefined,
    androidLink: undefined,
  },
];
