import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import ResumeLayoutPage from "./pages/layouts/ResumeLayoutPage";
import AboutMePage from "./pages/AboutMePage";
import MyResumePage from "./pages/MyResumePage";
import MyPortfolioPage from "./pages/MyPortfolioPage";
import BlogPage from "./pages/BlogPage";
import ContactPage from "./pages/ContactPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/resume" element={<ResumeLayoutPage />}>
        <Route index element={<AboutMePage />}  />
        <Route path="aboutme" element={<AboutMePage />} />
        <Route path="resume" element={<MyResumePage />} />
        <Route path="portfolio" element={<MyPortfolioPage />} />
        <Route path="blog" element={<BlogPage />} />
        <Route path="contact" element={<ContactPage />} />
      </Route>
    </Routes>
  );
}

export default App;
