import appDecl from "./declarations";

const getAppLang = (): string => {
  const elt = localStorage.getItem(appDecl.currentLang);
  return elt ?? "en";
};

const retLang = (
  enCont: string,
  frCont: string,
  rwCont: string = ""
): string => {
  const lang = getAppLang();

  return lang === "en"
    ? enCont
    : lang === "fr"
    ? frCont
    : lang === "rw"
    ? rwCont
    : enCont;
};

export default function GetLanguage() {
  return {
    webmobile: retLang(
      "Web & Mobile", 
      "Web & Mobile", 
      ""
    ),
    webapp: retLang(
      "Web App", 
      "Appli Web", 
      ""
    ),
    
    wordpress: retLang(
      "Wordpress", 
      "Wordpress", 
      ""
    ),
    mobiledesk: retLang(
      "Mobile & Desktop", 
      "Mobile & Bureau", 
      ""
    ),
    desktop: retLang(
      "Desktop", 
      "Bureau", 
      ""
    ),



    archivixDescr: retLang(
      "Document management software and signing automation", 
      "Logiciel de gestion de documents et automatisation des signatures", 
      ""
    ),
    colombDescr: retLang(
      "School website an management tools", 
      "Site Internet de l'école et outils de gestion", 
      ""
    ),
    colombMgtDescr: retLang(
      "School website management app", 
      "Application de gestion de site Web scolaire", 
      ""
    ),
    colombAlumniDescr: retLang(
      "School Alumni community social network", 
      "Réseau social communautaire des anciens élèves de l'école", 
      ""
    ),
    congoDocsDescr: retLang(
      "Pick or post document. Earn gift.", 
      "Choisissez ou publiez un document. Gagnez un cadeau.", 
      ""
    ),
    ascenDescr: retLang(
      "(ASCEN NGO) Empowering people, educate people", 
      "(ONG ASCEN) Autonomiser les gens, éduquer les gens", 
      ""
    ),
    stocViewerDescr: retLang(
      "Inventory and sales management software", 
      "Logiciel de gestion des stocks et des ventes", 
      ""
    ),
    villedeBanguiWeb: retLang(
      "Read news about the City of Bangui", 
      "Lire l'actualité de la Ville de Bangui", 
      ""
    ),
    villedeBanguiPortal: retLang(
      "City services Portal", 
      "Portail des services de la ville", 
      ""
    ),
    villedeBanguiManagt: retLang(
      "All services related to the website- Management", 
      "Gestion de tous les services liés au site web", 
      ""
    ),
    villedeBanguiArchives: retLang(
      "Document management application for the City of Bangui", 
      "Application de gestion documentaire de la Ville de Bangui", 
      ""
    ),
    falconInsurWeb: retLang(
      "Insurance and Financial Services - Website", 
      "Assurances et services financiers - Site Web", 
      ""
    ),
    innovationStratWeb: retLang(
      "Effortless insurance payment service provider", 
      "Fournisseur de services de paiement d'assurance sans effort", 
      ""
    ),
    fis250InsurWeb: retLang(
      "Providing an insurance tool in the hands of policyholders", 
      "Mettre à disposition des assurés un outil d'assurance", 
      ""
    ),
    wawashopWeb: retLang(
      "A handy tool/app for super markets", 
      "Un outil/application pratique pour les supermarchés", 
      ""
    ),

    
    tripAfricaBookDescr: retLang(
      "Booking a bus using a mobile or a web app", 
      "Réserver un bus à l'aide d'un appli mobile ou web", 
      ""
    ),
    tripAfricaRailsDescr: retLang(
      "Bus network operators tool", 
      "Outil des opérateurs de réseaux de bus", 
      ""
    ),
    tripAfricaManagtDescr: retLang(
      "TripAfrica Software Manager Tool", 
      "Outil de gestion du logiciel TripAfrica", 
      ""
    ),
    muaAgentsDescr: retLang(
      "Management of MUA Rwanda agents", 
      "Gestion des agents de MUA Rwanda", 
      ""
    ),
    sengaAgentsDescr: retLang(
      "Insurance company agent management software", 
      "Logiciel de gestion des agents des sociétés d'assurance", 
      ""
    ),
    mobiAssurDescr: retLang(
      "Insurance operations management software", 
      "Logiciel de gestion des opérations d'assurance", 
      ""
    ),
    motorateDescr: retLang(
      "Collect traffic offenses to produce national statistics", 
      "Recueillir les infractions routières pour en faire des statistiques nationales", 
      ""
    ),

    
    brokerixDescr: retLang(
      "Management of insurance brokerage company operations", 
      "Gestion des opérations des sociétés de courtage en assurance", 
      ""
    ),

    wimDescrDescr: retLang(
      "Management of insurance company operations", 
      "Gestion des opérations des sociétés d'assurance", 
      ""
    ),

    cimDescrDescr: retLang(
      "Management of insurance company operations/CORAR", 
      "Gestion des opérations des sociétés d'assurance/CORAR", 
      ""
    ),


    my_work: retLang("My Work", "Mon Travail", ""),
    api_framework: retLang("Api Framework", "Framework Api", ""),
    mobile_framework: retLang("Mobile Framework", "Framework mobile", ""),
    framework: retLang("Framework", "Framework", ""),
    pers_resume_serv: retLang("Services and Personal Resume", "Services et CV personnel", ""),
    cur_vitae: retLang("Curr. Vitae", "Curr. Vitae", ""),
    networkError: retLang("Network error", "Erreur de réseau", ""),
    networkErrorMsg: retLang(
      "There is a network error on this device. Check this issue and try again.",
      "Il y a une erreur réseau sur cet appareil. Vérifiez ce problème et réessayez.",
      ""
    ),
    fldRequired: retLang(
      "This field is required",
      "Ce champ est obligatoire",
      ""
    ),
    waitingforPexelsServer: retLang(
      "Waiting for Pexels server",
      "En attente du serveur Pexels"
    ),

    jan_lng: retLang("January", "Janvier", "Mutarama"),
    feb_lng: retLang("February", "Février", "Gashyantare"),
    mar_lng: retLang("March", "Mars", "Werurwe"),
    apr_lng: retLang("April", "Avril", "Mata"),
    may_lng: retLang("May", "Mai", "Gicurasi"),
    jun_lng: retLang("Jun", "Juin", "Kamena"),
    jul_lng: retLang("July", "Juillet", "Nyakanga"),
    aug_lng: retLang("August", "Août", "Kanama"),
    sept_lng: retLang("September", "Septembre", "Nzeri"),
    oct_lng: retLang("October", "Octobre", "Ukwakira"),
    nov_lng: retLang("November", "Novembre", "Ugushyingo"),
    dec_lng: retLang("December", "Decembre", "Ukuboza"),

    jan_shrt: retLang("Jan", "Janr", "Muta"),
    feb_shrt: retLang("Feb", "Fév", "Gashy"),
    mar_shrt: retLang("Mar", "Mars", "Weru"),
    apr_shrt: retLang("Apr", "Avr", "Mata"),
    may_shrt: retLang("May", "Mai", "Gicu"),
    jun_shrt: retLang("Jun", "Juin", "Kame"),
    jul_shrt: retLang("July", "Juil", "Nyaka"),
    aug_shrt: retLang("Aug", "Août", "Kana"),
    sept_shrt: retLang("Sept", "Sept", "Nzeri"),
    oct_shrt: retLang("Oct", "Oct", "Ukwa"),
    nov_shrt: retLang("Nov", "Nov", "Ugus"),
    dec_shrt: retLang("Dec", "Dec", "Uku"),

    monday_lng: retLang("Monday", "Lundi", ""),
    tuesday_lng: retLang("Tuesday", "Mardi", ""),
    wednday_lng: retLang("Wednesday", "Mercredi", ""),
    thursday_lng: retLang("Thursday", "Jeudi", ""),
    friday_lng: retLang("Friday", "Vendredi", ""),
    saturday_lng: retLang("Saturday", "Samedi", ""),
    sunday_lng: retLang("Sunday", "Dimance", ""),

    monday_shrt: retLang("Mon", "Lun", ""),
    tuesday_shrt: retLang("Tue", "Mar", ""),
    wednday_shrt: retLang("Wed", "Mer", ""),
    thursday_shrt: retLang("Thu", "Jeu", ""),
    friday_shrt: retLang("Fri", "Ven", ""),
    saturday_shrt: retLang("Sat", "Sam", ""),
    sunday_shrt: retLang("Sun", "Dim", ""),

    waitServerResp: retLang(
      "Waiting for server response...",
      "Veuillez patienter la reponse du serveur...",
      ""
    ),

    english_m: retLang("English", "Anglais", ""),
    french_m: retLang("French", "Français", ""),
    rwanda_m: retLang("Kinyarwanda", "Kinyarwanda", ""),
    swahili_m: retLang("Swahili", "Swahili", ""),
    lingala_m: retLang("Lingala", "Lingala", ""),
    other_m: retLang("Other language", "Autre langue", ""),
    reload: retLang("Reload", "Recharger", ""),
    cancel: retLang("Cancel", "Annuler", ""),
    ok: retLang("OK", "OK", ""),
    yes: retLang("Yes", "Oui", ""),
    messageDate: retLang("Message date", "Date message", ""),
    networkName: retLang("Network name", "Nom du réseau", ""),
    home: retLang("Home", "Acceuil", ""),
    search: retLang("Search", "Chercher", ""),
    saving: retLang("Saving", "Enregistrement", ""),
    ourPartners: retLang("Our partners", "Nos Partenaires", ""),
    clcickHereToSelect: retLang(
      "Click here to select",
      "Cliquez ici pour sélectionner",
      ""
    ),
    resetPassword: retLang(
      "Reset your password",
      "Modifier votre mot de passe",
      ""
    ),

    jobTitle: retLang(
      "Data Analyst / Web Developer / Mobile Developer",
      "Analayste de Données / Développeur Web / Développeur Mobile",
      ""
    ),
    about_me: retLang("About me", "A propos", ""),
    who_am_i: retLang("Who am I", "Qui suis-je", ""),
    resume: retLang("Resume", "CV", ""),
    cv_long: retLang("Curriculum Vitae", "Curriculum Vitae", ""),
    cv_short: retLang("Curr. Vitae", "Curr. Vitae", ""),
    portfolio: retLang("Portfolio", "Travaux", ""),
    my_works: retLang("My works", "Mes travaux", ""),
    blog: retLang("Blog", "Blog", ""),
    contact: retLang("Contact", "Contacts", ""),
    say_hello: retLang("Say Hello", "Dis Coucou", ""),
    abt_me_bio: retLang(
      "Data enthusiast, IT specialist, I spend my time creating solutions for small " +
        "and medium businesses. I train people wishing to migrate to new technologies relating to " +
        "Web 2.0. I also spend my time dissecting the DAX and M languages in order to see how to " +
        "interpret companies' datawarehouse.",
      "Passionné de données, spécialiste informatique, je passe mon temps à créer des solutions pour " +
        "les petites et moyennes entreprises. Je forme des personnes souhaitant migrer vers les nouvelles " +
        "technologies liées au Web 2.0. Je passe également mon temps à disséquer les langages DAX et M afin " +
        "de voir comment interpréter l'entrepôt de données des entreprises. ",
      ""
    ),
    all: retLang("All", "Toutes", ""),
    personnal_info: retLang("Personal Info", "Infos personnelles", ""),
    email: retLang("Email", "Email", ""),
    website: retLang("Website", "Site web", ""),
    //webapp: retLang("Web app", "Appli web", ""),
    mobile_app: retLang("Mobile app", "Appli mobile", ""),
    web_and_mmobile: retLang("Web and Mobile", "Web et Mobile", ""),
    archives: retLang("Archives", "Archives", ""),
    admin: retLang("Administration", "Administration", ""),
    skype: retLang("Skype", "Skype", ""),
    phone: retLang("Phone", "Tél", ""),
    mobile_phone: retLang("Mobile Phone", "Tél. Mobile", ""),
    download_my_cv: retLang("Download my cv", "Téléchargez mon CV ", ""),
    print_my_cv: retLang("Print my cv", "Imprimez mon CV ", ""),
    address: retLang("Adress", "Addresse", ""),
    contact_info: retLang("Contact Info", "Infos de contact", ""),
    marital_status: retLang("Marital status", "Etat civil", ""),
    freelance: retLang("Freelance", "Free-lance", ""),
    social: retLang("Social", "Social", ""),
    experience: retLang("Experience", "Expérience", ""),
    jan2016: retLang("January 2016", "Janvier 2016", ""),
    december_2012: retLang(
      "December 2012 - December 2015",
      "Décembre 2012 - Décembre 2015",
      ""
    ),
    december_2020: retLang("December 2020", "Décembre 2020", ""),
    september_2005: retLang(
      "September 2005 - December 2012",
      "Septembre 2005 - Décembre 2012",
      ""
    ),
    january_2002: retLang(
      "January 2002 - July 2005",
      "Janvier 2002 - Juillet 2005",
      ""
    ),
    january_2001: retLang(
      "September 2000 - December 2001",
      "Septembre 2000 - Décembre 2001",
      ""
    ),
    business_intelligence: retLang(
      "Business Intelligence",
      "Business Intelligence",
      ""
    ),
    coursera_certification: retLang(
      "Coursera Cerification",
      "Coursera Cerification",
      ""
    ),
    lang_french: retLang("French", "Français", ""),
    lang_english: retLang("English", "Anglais", ""),
    current: retLang("Current", "Actuel", ""),
    lead_web_mob_dev: retLang(
      "Lead Web & Mobile Developer",
      "Développeur Web & Mobile en Chef ",
      ""
    ),
    lead_bus_data_anal: retLang(
      "Lead Business Data Analyst",
      "Analyste Principal des Données des Affaires",
      ""
    ),
    soft_anal_dev: retLang(
      "Software Analyst & Developer",
      "Analyste & Dévelopeur Logiciels",
      ""
    ),
    head_it_dept: retLang(
      "Head of IT Department",
      "Chef Départment Informatique",
      ""
    ),
    system_analyst: retLang(
      "System and Network Analyst",
      "Analyste Système et Réseau",
      ""
    ),
    july_2000: retLang("July 2000", "Juillet 2000", ""),
    jun_2020: retLang("Jun 2020", "Juin 2020", ""),
    december_2018: retLang("December 2018", "Décembre 2018", ""),
    bachelor_degree: retLang("Bachelor degree", "Licence", ""),
    info_gestion: retLang("IT and Management", "Informatique de Gestion", ""),
    certification: retLang("Certification", "Certification", ""),
    assets: retLang("Assets", "Atouts", ""),
    assets_list: retLang(
      "Responsible, Diligence, Labour, Rigor, Creative, Funny, Great Communicator, Flexible",
      "Responsable, Diligence, Travail, Rigueur, Créatif, Drôle, Grand Communicateur, Flexible",
      ""
    ),
    analyse_programming: retLang(
      "Analysis & Programming ",
      "Analyse & Programmation",
      ""
    ),
    software_developer: retLang(
      "Software Developer",
      "Développeur de Logiciels",
      ""
    ),
    head_tech_dept: retLang(
      "Head of Technical Department",
      "Chef du Département Technique",
      ""
    ),
    exchange_messaging_specialist: retLang(
      "Exchange Messaging Specialist",
      "Spécialiste Messagerie Exchange ",
      ""
    ),
    job_1_descipt_a: retLang(
      "Development of web and mobile software. A large list of software " +
        "affecting almost all facets of business management. Insurance, Online " +
        "sales, Business management and decision-making tools. ",
      "Développement des logiciels web et mobiles. Une grande palmares " +
        "des logiciels touchant presque toutes les facettes de gestion " +
        "des entreprises.Assurances, Ventes en ligne, Gestion des entreprises " +
        "et outils décisionnels.",
      ""
    ),
    job_1_descipt_b: retLang(
      "Specialist in Business Intelligence software, I used to analyse data " +
        "to make data visualizations allowing the management to make good decisions " +
        "based on business dashboard.",
      "Spécialiste des logiciels de Business Intelligence, je devais " +
        "analyser des données pour faire des visualisations de données " +
        "permettant au management de prendre de bonnes décisions en " +
        "fonction du tableau de bord métier. ",
      ""
    ),
    job_2_descipt_a: retLang(
      "It can be fun to analyze customer software needs. It was my job to advise " +
        "them so that there was a link between their description and the end result. " +
        "Insurance brokers had a strong partner when it came to explaining their " +
        "business software needs.",
      "Il peut être amusant d'analyser les besoins logiciels des clients.C'était mon " +
        "travail de les conseiller pour qu'il y ait un lien entre leur description et le " +
        "résultat final.Les courtiers d'assurance avaient un partenaire solide lorsqu'il " +
        "s'agissait d'expliquer leurs besoins en logiciels d'entreprise.",
      ""
    ),
    job_2_descipt_b: retLang(
      "The hosting of websites on different platforms was in my responsibilities. " +
        "I worked extensively with Arvixe.com to host online servers, virtual servers, " +
        "applications as well as business solutions.",
      "L'hébergement des sites web sur differentes plateformes était dans mes " +
        "responsabilités. J'ai longuement travaillé avec Arvixe.com pour héberger des " +
        "serveurs en ligne, des serveurs virtuels, des applications ainsi que des solutions " +
        "d'entreprises.",
      ""
    ),
    job_corar_descript_1: retLang(
      "CORAR Ltd was, at that time, one of the largest insurance companies in Rwanda. " +
        "With his stature came a dilemma of meeting his needs for software and management tools. " +
        "For a short period of time I was in charge of developing all the general insurance management " +
        "modules and packing them into a single interconnected software that we called CIM - Corar " +
        "Insurance Manager. ",
      "La CORAR Ltd était, à cette époque, l'une de plus grandes sociétés d'assurance au Rwanda. Avec " +
        "sa stature venait un dilemme de combler ses besoins en logiciels et outils de gestion. Pour une courte " +
        "période de temps j'ai été chargé de déveloper tous les modules de gestion des assurances générales et " +
        "les emballer dans un seul logiciel interconnecté qu'on a baptisé CIM - Corar Insurance Manager.",
      ""
    ),
    job_corar_descript_2: retLang(
      "The CIM contains several management modules: Automobile Insurance, Miscellaneous Risk Insurance with " +
        "its innumerable sub-modules, Accounting, Treasury, Staff Manager as well as other tools",
      "La CIM contient plusieurs modules de gestion: Assurance Automobile, Assurance Risques Divers avec ses " +
        "innombrables sous modules, Comptabilité, Trésorerie, Gestion du Personnel ainsi que d'autres outils.",
      ""
    ),
    job_cogear_descript: retLang(
      "The work done at COGEAR Ltd gave birth to the first insurance software in Rwanda, " +
        "WIM (Wise Insurance Manager). This software, still used at Prime Ltd, contained all " +
        "the modules necessary for the management of general insurance products. ",
      "Le travail effectué chez COGEAR Ltd a donné naissance au premier logiciel d'assurance " +
        "au Rwanda, WIM (Wise Insurance Manager). Ce logiciel, encore utilisé chez Prime Ltd contenait " +
        "tous les modules nécessaires pour le gestion des produits d'assurance générale.",
      ""
    ),
    job_axiom_descript: retLang(
      "Axiom Computers was a company specializing in the installation of Ms Exchange messaging, " +
        "maintenance as well as technical advice to its customers. My role was to " +
        "follow up and analyze clients' needs to ensure that the installation matched their " +
        "requests.",
      "Axiom Computers était une société spécialisée dans l'installation de la messagerie " +
        "Ms Exchange, de la maintenance ainsi que des conseils techniques auprès de ses clients. " +
        "Mon rôle était de faire le suivi et l'analyse des besoins des clients pour s'assurer que " +
        "l'installation cocnorde avec leurs demandes.",
      ""
    ),
    great_support: retLang("Great support", "Excellent soutien", ""),
    great_support_msg: retLang(
      "I support my clients in the field of software. I go over their " +
        "needs and see exactly how to upgrade their software accordingly.",
      "J'accompagne mes clients dans le domaine des logiciels. Je passe en " +
        "revue leurs besoins et vois exactement comment mettre à niveau leurs " +
        "logiciels en conséquence.",
      ""
    ),
    website_hosting: retLang("Website Hosting", "Hébergement Sites Web", ""),
    website_hosting_msg: retLang(
      "Arvixe.com has been my partner of choice for almost 10 years now. I am proud to host PHP, Asp.Net, NodeJS and virtual servers with It.",
      'Arvixe.com est mon partenaire de choix pour presque 10 ans déjà. Je suis fier d"héberger avec lui des sites en PHP, en Asp.Net, en NodeJS ainsi que des serveurs virtuels.',
      ""
    ),
    office_applications: retLang(
      "Office Applications",
      "Applications Office",
      ""
    ),
    office_applications_msg: retLang(
      "I develop full Office applications using Excel VBA or Access VBA for low budget businesses. ",
      "Je développe des applications complètes Office en utilisant Excel VBA ou Access VBA pour les entreprises à petit budget.",
      ""
    ),
    website_design: retLang("Website Design", "Conception des Sites Web ", ""),
    website_design_msg: retLang(
      "Professional websites are important in capturing the attention of visitors. It is " +
        "possible to link a database to record visitor information.",
      "Les sites Web professionnels sont importants pour capter l'attention des visiteurs. " +
        "Il est possible de lier une base de données pour enregistrer les informations des visiteurs.",
      ""
    ),
    web_desk_mob_app: retLang(
      "Web/Mobile/Desktop Apps",
      "Applications Web/Mobile/Bureau ",
      ""
    ),
    web_desk_mob_app_msg: retLang(
      "I develop different management applications on different platforms: Web, Mobile and Desktop.",
      "Je développe differentes applications de gestion sur differentes plateformes: Web, Mobile et Bureau.",
      ""
    ),
    business_intel_apps: retLang(
      "Business Intelligence Services",
      "Services de Business Intelligence",
      ""
    ),
    business_intel_apps_msg: retLang(
      "I support medium-sized companies in the analysis of their data using powerful " +
        "business intelligence tools. Software like Tableau, Power BI and Sql Server " +
        "analysis Services.",
      "J'accompagne les moyennes entreprises dans l'analyse de leurs données en " +
        "utilisant des puissants outils dans l'intelligence des affaires. Des logiciels " +
        "comme Tableau, Power BI et Sql Server Analysis Services.",
      ""
    ),
    customer_portal: retLang("Customer Portal", "Portail Client", ""),
    web_mobile_application: retLang(
      "Web & Mobile Application",
      "Application Web & Mobile",
      ""
    ),
    web_application: retLang("Web Application", "Application Web", ""),
    web_site: retLang("Website", "Site Web", ""),
    web_portal: retLang("Web portal", "Portail web", ""),
    android_mob_app: retLang(
      "Android Mobile Application",
      "Application Mobile Android",
      ""
    ),
    destop: retLang("Desktop", "Bureau", ""),
    destop_app: retLang("Desktop Application", "Application de Bureau", ""),
    my_thoughts: retLang("My thoughts", "Mes pensées", ""),
    the_blog: retLang("The Blog", "Le Journal", ""),
    read_blog: retLang("Read blog", "Lire le Journal", ""),
    dashb_excel_chart_title: retLang(
      "Excel Charts: Why and when?",
      "Graphiques Excel: pourquoi et quand?",
      ""
    ),
    dashb_excel_chart_body: retLang(
      "Some Data scientist folks using Excel Power Pivot and Excel Power Query " +
        "feel uncomfortable when designing dashboard full of charts. Each excel chart " +
        "has its own purpose and tends to provide a specific message to a decision maker. " +
        "When and how to use them?",
      "Graphiques Excel: pourquoi et quand?",
      "Certains spécialistes des données qui utilisent Excel Power Pivot et Excel " +
        "Power Query se sentent mal à l'aise lors de la conception d'un tableau de bord " +
        "rempli de graphiques. Chaque graphique Excel a son propre objectif et tend à " +
        "fournir un message spécifique à un décideur. Quand et comment les utiliser?"
    ),
    powerbi_dash_report_title: retLang(
      "Power BI Report vs Dashboard",
      "Raport vs Tableau de Bord dans Power BI",
      ""
    ),
    powerbi_dash_report_body: retLang(
      "In Power BI Desktop, the word Dashboard is not used in the user " +
        "interface. However, there are many who, when creating reports in " +
        "Power BI, strangely use the word Dashboard. What is the difference " +
        "and in what context to use the two words?",
      "En Power BI Desktop, le mot Dashboard (Tableau de Bord) n'est pas utilisé " +
        "dans l'interface utilisateur. Ils sont pourtant nombreux qui, en créant des " +
        "rapports en Power BI, utilisent étrangement le mot Tableau de Bord. " +
        "C'est quoi la différence et dans quel contexte les utiliser?",
      ""
    ),
    m_language_first_steps_title: retLang(
      "Power Query M Language - First Steps in Mentorship",
      "Premiers pas dans le mentorat - Language M",
      ""
    ),
    m_language_first_steps_body: retLang(
      "I want to convert to mentoring in the field of Business Intelligence. " +
        "Judge me based on these first steps with Power Query M language in Excel, " +
        "Power BI as well as Ms Sql Server Analysis Services.",
      "Je voudrais me convertir dans le mentorat dans le domaine de Business " +
        "Intelligence. Jugez-moi sur base de ces premiers pas avec le langage M de " +
        "Power Query en Excel, Power BI ainsi que dans le Ms Sql Server Analysis Services",
      ""
    ),
    learning_python_business_intell_title: retLang(
      "How to learn Python for Data Engineering",
      "Comment apprendre Python pour l'ingénierie des données",
      ""
    ),
    learning_python_business_intell_body: retLang(
      "Python is the number one language used in the world according to many reports. How to learn it in the field of business intelligence? What libraries are needed for data engineering? ",
      "Python est en tête dans les langages utilisés dans le monde selon beaucoup des rapports. Comment l'apprendre dans le domaine de l'intelligence des affaires? Quelles sont les bibliothèques nécessaires pour l'ingénierie des données?",
      ""
    ),
    category: retLang("Category", "Categorie", ""),
    year: retLang("Year", "Année", ""),
    description: retLang("Description", "Description", ""),
    database: retLang("Database", "Base de données", ""),
    ios_link: retLang("iOS link", "Lien Apple", ""),
    android_link: retLang("Android link", "Lien Android", ""),
    inprogress: retLang("In progress", "En cours", ""),
    andr_ios_web_app: retLang(
      "Android, iOS and Web App",
      "Appli Android, iOS et Web",
      ""
    ),
    web_app_android: retLang(
      "Web Application - Android",
      "Appli Web & Android",
      ""
    ),
    mobile_application: retLang("Mobile Application", "Application mobile", ""),
    map_location: retLang("Map location", "Emplacement de la carte", ""),
    personal_info: retLang("Personal Info", "Infos personnelles", ""),
    drop_on_in: retLang("Drop on in", "Passez par", ""),
    give_me_call: retLang("Give me a call", "Apelez moi", ""),
    lets_connect: retLang("Let's connect", "Connectons-nous", ""),
    office: retLang("Office", "Bureau", ""),
    reach_me_here: retLang("Reach me here", "Rejoignez-moi ici", ""),
    name: retLang("Name", "Nom", ""),
    title: retLang("Title", "Titre", ""),
    language: retLang("Language", "Langue", ""),
    english: retLang("English", "Anglais", ""),
    french: retLang("French", "Français", ""),
    mr: retLang("Mr.", "Mr.", ""),
    mrs: retLang("Mrs.", "Mme.", ""),
    ms: retLang("Ms.", "Mlle.", ""),
    sex: retLang("Sex", "Sexe", ""),
    male: retLang("Male", "Masculin", ""),
    female: retLang("Female", "Féminin", ""),
    field_required: retLang(
      "This field is required!",
      "Ce champ est requis!",
      ""
    ),
    email_seems_not_valid: retLang(
      "This email format seems not valid (name@domain.com)",
      "Ce format d'e-mail ne semble pas valide (nom@domaine.com) ",
      ""
    ),
    first_name: retLang("First Name", "Nom de famille", ""),
    last_name: retLang("Last Name", "Prénom", ""),
    subject: retLang("Subject", "Objet", ""),
    interest: retLang("Interest", "Intérêt", ""),
    min_length_error_3: retLang(
      "This field is too short to be accepted. 3 characters are needed!",
      "Ce champ est trop court pour être accepté. 3 personnages sont nécessaires!",
      ""
    ),
    min_length_error_10: retLang(
      "This field is too short to be accepted. 10 characters are needed!",
      "Ce champ est trop court pour être accepté. 10 personnages sont nécessaires!",
      ""
    ),
    min_length_error_15: retLang(
      "This field is too short to be accepted. 15 characters are needed!",
      "Ce champ est trop court pour être accepté. 15 personnages sont nécessaires!",
      ""
    ),
    message: retLang("Message", "Message", ""),
    subscribe_msg: retLang(
      "Sign up for our monthly newsletter to receive tips and promotions. You can unsubscribe at any time.",
      "Inscrivez-vous à notre newsletter mensuelle pour recevoir des conseils et des promotions. Vous pouvez vous désinscrire à tout moment.",
      ""
    ),
    send: retLang("Send", "Envoyer", ""),
    submitting_data_server: retLang(
      "Submitting your request. Please wait...",
      "Envoyer",
      ""
    ),
    hosting: retLang("Hosting", "Hébergement", ""),
    hosting_asp: retLang("Hosting - Asp.Net", "Hébergement - Asp.Net", ""),
    hosting_linux: retLang("Hosting - Linux", "Hébergement - Linux", ""),
    hosting_node: retLang("Hosting - NodeJs", "Hébergement - NodeJs", ""),
    mobile_app_all: retLang(
      "Mobile App (iOS, Android)",
      "Appli Mobile (iOS, Android)",
      ""
    ),
    training_mentorship: retLang(
      "Training (Mentorship)",
      "Formation (mentorat)",
      ""
    ),
    bus_intell_pbi: retLang(
      "Business Intelligence - Power BI",
      "Business Intelligence - Power BI",
      ""
    ),
    bus_intell_tbl: retLang(
      "Business Intelligence - Tableau",
      "Business Intelligence - Tableau",
      ""
    ),
    bus_intell_python: retLang(
      "Business Intelligence - Python",
      "Business Intelligence - Python",
      ""
    ),
    phone_error_msg: retLang(
      "This phone number requires at least 8 digits preceded by the dialing code.",
      "Ce numéro de téléphone nécessite au moins 8 chiffres précédés du code de numérotation.",
      ""
    ),
    email_sample: retLang("you@domain.com", "vous@domaine.com", ""),
    country_origin: retLang("Country of origin", "Pays d'origine", ""),
    city: retLang("City", "Ville", ""),
    dial_code: retLang("Dialing code", "Indicatif de numérotation", ""),
    desk_appli_all: retLang(
      "Desktop application (Mac-Windows-ElectronJS)",
      "Appli de Bureau (Mac-Windows-ElectronJS)",
      ""
    ),
    success: retLang("Success", "Succès", ""),
    email_sent_success: retLang(
      "Your data have been submitted with success. Kindly review the details in your Inbox for further details.",
      "Vos données ont été soumises avec succès. Veuillez consulter les détails dans votre boîte de réception pour plus de détails.",
      ""
    ),
    okBtn: retLang("OK", "OK", ""),
    yesBtn: retLang("Yes", "Oui", ""),
    cancelBtn: retLang("Cancel", "Annuler", ""),
    application: retLang(
      "Application (Web, Mobile, Desktop)",
      "Application (Web, Mobile, Bureau)",
      ""
    ),
    not_yet: retLang("Not yet", "Pas encore", ""),
    in_progress: retLang("In progress", "En cours", ""),
    my_resume: retLang("My Resumé", "Mon CV", ""),
    justNow: retLang("Just now", "En ce moment", ""),

    error: retLang("Error", "Erreur", ""),
    loading: retLang("Loading", "Chargement", ""),
    login: retLang("Login", "Connection", ""),
    loggin: retLang("Logging In", "Connection", ""),
    cantShareDev: retLang(
      "This device cannot share files.",
      "Cet appareil ne partage pas les fichiers.",
      ""
    ),
    waitingforServerResp: retLang(
      "Waiting for server response",
      "En attente de la reponse du serveur"
    ),
  };
}
