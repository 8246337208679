/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import getLangs from "src/utils/languages";

const languages = getLangs();

export const HomePage = () => {
  document.title = `${languages.loading}... | JK Resume"`;
  const navigation = useNavigate();

  useEffect(() => {
    const oElt = setTimeout(() => {
      navigation("/resume");
    }, 3000);

    return () => clearTimeout(oElt);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <div
        style={{
          width: 60,
          height: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <i className="fa fa-spin fa-spinner fa-3x"></i>
      </div>
    </div>
  );
};
