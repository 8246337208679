/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import getLang from "src/utils/languages";
import jkSign from "src/assets/images/signature.png";

const languages = getLang();

const AboutMePage = () => {
  document.title = `${languages.about_me} | ${languages.pers_resume_serv}`;
  return (
    <section id="about" className="bg-white t-center">
      <div className="about">
        <div className="content">
          <div className="block-content mb-100">
            <div className="row">
              <div className="col-md-12  ">
                <div className="main-title profile">
                  <h1 className="mb-20">Kayihura James</h1>
                  <h3>{languages.jobTitle}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100">
            <div className="row">
              <div className="col-md-12  ">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">{languages.jobTitle}</h2>
                </div>
              </div>
            </div>
            <p className="lead-intro">“ {languages.abt_me_bio} “</p>
            <img className="signature mt-20" src={jkSign} alt="" />
          </div>
          <div className="block-content mb-100 pb-30">
            <div className="row">
              <div className="col-md-12  ">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">{languages.personnal_info}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-md-offset-3  col-sm-8 col-sm-offset-3 ">
                <div className="listing mt-40">
                  <div className="listing-inner">
                    <div className="listing-event">
                      <ul className="data left">
                        <li className="emph-1">{languages.name}</li>
                        <li className="emph-1">{languages.marital_status}</li>
                        <li className="emph-1">{languages.freelance}</li>
                      </ul>

                      <ul className="data right">
                        <li>KAYIHURA A. James</li>
                        <li>Single</li>
                        <li>Available</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100  pb-30">
            <div className="row">
              <div className="col-md-12  ">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">{languages.contact_info}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-md-offset-3  col-sm-8 col-sm-offset-3 ">
                <div className="listing mt-40">
                  <a
                    className="uppercase emph-1 btn-1"
                    style={{ cursor: "pointer" }}
                  >
                    {languages.download_my_cv}
                  </a>
                  <a
                    className="uppercase emph-1 btn-2"
                    style={{ cursor: "pointer" }}
                  >
                    {languages.print_my_cv}
                  </a>
                  <div className="listing-inner">
                    <div className="listing-event">
                      <ul className="data left">
                        <li>
                          <span className="emph-1">{languages.address}</span> :
                          <br />
                          KAYIHURA James
                          <br />
                          Café de Gisozi, Kigali,
                          <br />
                          KG597 Road <br />
                          Rwanda
                        </li>
                      </ul>
                      <ul className="data right">
                        <li>
                          <span className="emph-1">{languages.email}</span>:&nbsp;
                          jkayihura@msn.com
                        </li>
                        <li>
                          <span className="emph-1">{languages.website}</span>:&nbsp;
                          <span>jameskayihura.com</span>
                        </li>
                        <li>
                          <span className="emph-1">{languages.phone}</span>:&nbsp;
                          +250 788 307 310
                        </li>
                        <li>
                          <span className="emph-1">{languages.social}</span>:&nbsp;
                          @jkayihura
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100">
            <div className="row">
              <div className="col-md-12  ">
                <div className="sub-title mb-40">
                  <h2 className="uppercase">Instagram</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul className="insta-Feed" id="instafeed"></ul>
              </div>
            </div>
          </div>
          <div className="block-content">
            <div className="row">
              <div className="col-md-12">
                <span className="page-number emph-1">- 1/5 -</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMePage;
