/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";

import { Link, Outlet, useLocation } from "react-router-dom";

import profileImg from "src/assets/images/j_k_profile.png";
import GetLangs from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

import flagUk from "src/assets/images/flag_uk.svg";
import flagFr from "src/assets/images/flag_france.svg";

import abtMeImg from "src/assets/images/menu_icon_abtme.png";
import resumeImg from "src/assets/images/menu_icon_resume.png";
import portfolioImg from "src/assets/images/menu_icon_share.png";
import blogImg from "src/assets/images/menu_icon_blog.png";
import contactImg from "src/assets/images/menu_icon_contact.png";

const languages = GetLangs();
const appFxs = generalFxs();

const isLangEnglish = appFxs.getLocalLanguage() === "en";

const menusApp: {
  btnType: "abtme" | "resume" | "portfolio" | "blog" | "contact";
}[] = [
  { btnType: "abtme" },
  { btnType: "resume" },
  { btnType: "portfolio" },
  { btnType: "blog" },
  { btnType: "contact" },
];

const ResumeLayoutPage = () => {
  const currentYear = new Date().getFullYear();
  const setLanguage = (lng: string) => {};

  return (
    <div className="" id="wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-2 left-content pd-r0 pd-l0">
            <header id="header">
              <div className="main-header">
                {/* image profile */}
                <div className="img-profile">
                  <img src={profileImg} alt="" />
                  <div className="name-profile text-center">
                    <h5 className="uppercase text-white">Kayihura James</h5>
                  </div>
                </div>

                {/* Main navigation */}
                <nav
                  id="main-nav"
                  className="main-nav clearfix t-center uppercase tabbed"
                >
                  <ul className="clearfix">
                    {menusApp.map((oMenu, idx) => (
                      <MenuIconClicker key={idx} btnType={oMenu.btnType} />
                    ))}
                  </ul>
                </nav>
              </div>
            </header>
          </div>
          <div className="col-md-9 right-content pd-r0 pd-l0">
            <Outlet />
          </div>
          <footer className="footer">
            <div className="col-md-1 pd-l0">
              <ul className="social">
                <li>
                  <a
                    href="https://www.facebook.com/jkayihura"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/james-kayihura-78680133/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    href="https://twitter.com/jkayihura"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="lang">
                  {isLangEnglish && (
                    <a
                      style={{ cursor: "pointer" }}
                      title={languages.french_m}
                      rel="noreferrer"
                      onClick={() => setLanguage("fr")}
                    >
                      <img src={flagFr} alt="" />
                    </a>
                  )}
                  {!isLangEnglish && (
                    <a
                      style={{ cursor: "pointer" }}
                      title={languages.english_m}
                      onClick={() => setLanguage("en")}
                      rel="noreferrer"
                    >
                      <img src={flagUk} alt="" />
                    </a>
                  )}
                </li>
              </ul>
              <div className="copyright">
                <p>
                  &copy; {currentYear}{" "}
                  <a
                    href="http://wiseobjects.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    WiseObjects Ltd
                  </a>
                  .
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ResumeLayoutPage;

const MenuIconClicker = ({
  btnType,
}: {
  btnType: "abtme" | "resume" | "portfolio" | "blog" | "contact";
}) => {
  const mHeader =
    btnType === "abtme"
      ? languages.about_me
      : btnType === "resume"
      ? languages.resume
      : btnType === "portfolio"
      ? languages.portfolio
      : btnType === "blog"
      ? languages.blog
      : btnType === "contact"
      ? languages.contact
      : languages.about_me;
  const mTitle =
    btnType === "abtme"
      ? languages.who_am_i
      : btnType === "resume"
      ? languages.cur_vitae
      : btnType === "portfolio"
      ? languages.my_work
      : btnType === "blog"
      ? languages.my_thoughts
      : btnType === "contact"
      ? languages.say_hello
      : languages.who_am_i;

  const mIcon =
    btnType === "abtme"
      ? abtMeImg
      : btnType === "resume"
      ? resumeImg
      : btnType === "portfolio"
      ? portfolioImg
      : btnType === "blog"
      ? blogImg
      : btnType === "contact"
      ? contactImg
      : abtMeImg;

  const route =
    btnType === "abtme"
      ? "resume/aboutme"
      : btnType === "resume"
      ? "resume/resume"
      : btnType === "portfolio"
      ? "resume/portfolio"
      : btnType === "blog"
      ? "resume/blog"
      : btnType === "contact"
      ? "resume/contact"
      : "resume/abtme";

  const location = useLocation();

  const isActive = useMemo(() => {
    const pathName = location.pathname!;
    const routeName = `/${route}`;

    const homePath = `/resume`;
    const homePathV2 = `/resume/`;

    const bHome = routeName === homePath || routeName === homePathV2;

    const isServed = pathName === routeName;
    return bHome || isServed;
    //return pathName.includes(route);
  }, [location, route]);

  return (
    <li>
      <Link to={`/${route}`} className={`${isActive && "active"} text-center`}>
        <div>
          <img src={mIcon} className="icon" alt="" />
        </div>
        {mHeader}
        <span>{mTitle}</span>
      </Link>
    </li>
  );
};
