//const pathWebapp = 'http://localhost:6122';
const pathWebapp = 'https://jameskayihura.com';


const apiPath = 'https://api.jameskayihura.com';
//const apiPath = 'http://localhost:4342';


const appDeclarations = {
    connectedUser: 'connected_____use235',
    connectedCompany: 'connected_company__001',
    currentLang: 'current_language',
    connectionStatus: 'connect_status',
    apiUrlTest: pathWebapp,
    apiPath: apiPath,
    webAppPath: pathWebapp,
    companyResourcesPath: `${apiPath}/cloud_data/company_res`,
    profilesPath: `${apiPath}/cloud_data/profiles`,
    appRootApi: `${apiPath}/api/`,
    apiRootTest: `${apiPath}/api/`,

    userAccessKey: 'user_access_key',

    company_code: 'JKRESAPP000-999-WOLLL',
    currentYearbook: 'current_year_book',

    currentDefaultSmsAcct: 'current_def_sms_acct',
    fileSizeallowed: 'file_size_allowed',
    itemsPerPage: 'items_per_page',
    currentDevise: 'current_devise',
    currentBranch: 'current_branch',
    userEmail: 'user_email',
    userPassword: 'userPassword',
    cartZoneArea: 'cart___zone_area____2363',
    mainPhoneNbr: '250 788 510 589',
    waitingEmailForPw: 'awaiting_____email_for_pw____x25'

}
export default appDeclarations;