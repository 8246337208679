/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import getLang from "src/utils/languages";

import excelChartsImg from 'src/assets/images/blog/excel_charts_bg.png';
import dashboardImg from 'src/assets/images/blog/power_bi_report_vs_dashboard.png';
import powerQryImg from 'src/assets/images/blog/first_steps_in_power_query.png'
import pythonBiImg from 'src/assets/images/blog/learning_python_for_business_intelligence.png'

const languages = getLang();

const BlogPage = () => {
  document.title = `${languages.the_blog} | ${languages.pers_resume_serv}`;


  const [searchVal, setSearchVal] = useState('');
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value);
  }

  return (
    <section id="blog" className="bg-white">
      <div className="blog">
        <div className="content">
          <div className="block-content mb-100 t-center">
            <div className="row">
              <div className="col-md-12">
                <div className="main-title ">
                  <h1 className="mb-20">{languages.the_blog}</h1>
                  <h5 className="uppercase">{languages.my_thoughts}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100">
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-8 col-md-offset-2">
                  <div className="search-header">
                    <form className="search-form" action="#">
                      <input
                        type="text"
                        title="Search for:"
                        name="s"
                        value={searchVal}
                        placeholder={`${languages.search}...`}
                        onChange={onChange}
                      />
                      <input type="submit" value="" />
                      <i className="icon-search-7"></i>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content mb-100 not-align-set">
            <div className="row">
              <div className="col-md-6">
                <div className="card mb-2">
                  <img
                    src={excelChartsImg}
                    className="card-img-top"
                    alt="Excel charts"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {languages.dashb_excel_chart_title}
                    </h5>
                    <p className="card-text">
                      {languages.dashb_excel_chart_body}
                    </p>
                    <a className="card-link ">{languages.read_blog}</a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="card">
                  <img
                    src={dashboardImg}
                    className="card-img-top"
                    alt="Power BI - Report VS Dashboard"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {languages.powerbi_dash_report_title}
                    </h5>
                    <p className="card-text">
                      {languages.powerbi_dash_report_body}
                    </p>
                    <a className="card-link c-pointer">{languages.read_blog}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card mb-2">
                  <img
                    src={powerQryImg}
                    className="card-img-top"
                    alt="M Language - first steps"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {languages.m_language_first_steps_title}
                    </h5>
                    <p className="card-text">
                      {languages.m_language_first_steps_body}
                    </p>
                    <a className="card-link c-pointer">{languages.read_blog}</a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card mb-2">
                  <img
                    src={pythonBiImg}
                    className="card-img-top"
                    alt="Python for Business Intelligence"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {languages.learning_python_business_intell_title}
                    </h5>
                    <p className="card-text">
                      {languages.learning_python_business_intell_body}
                    </p>
                    <a className="card-link c-pointer">{languages.read_blog}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-content t-center">
            <div className="row">
              <div className="col-md-12">
                <span className="page-number emph-1">- 4/5 -</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPage;
