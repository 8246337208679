/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

import { IAppRecord, listOfAppWorks } from "src/utils/tempo_data";


const languages = getLang();
const appFxs = generalFxs();

const categoriesApp:{id:string, label: string}[] = [
  {id: "webmobile", label: languages.webmobile},
  {id: "website", label: languages.website},
  {id: "webapp", label: languages.webapp},
  {id: "wordpress", label: languages.wordpress},
  {id: "mobiledesktop", label: languages.mobiledesk},
  {id: "mobile", label: languages.mobile_app},
  {id: "desktop", label: languages.desktop},
]



const MyPortfolioPage = () => {
  document.title = `${languages.portfolio} | ${languages.pers_resume_serv}`;

  const [category, setCategory] = useState<string|null>(null);

  const filteredApps = useMemo(() => {
    return !category ? listOfAppWorks:  listOfAppWorks.filter(o => o.category === category);
  }, [category])


  return (
    <section id="portfolio" className="bg-white">
      {/* <!--Portfolio--> */}
      <div className="portfolio">
        {/* <!--Content--> */}
        <div className="content">
          {/* <!--Block content--> */}
          <div className="block-content mb-100">
            {/* <!--Row--> */}
            <div className="row">
              <div className="col-md-12">
                <div className="main-title ">
                  <h1 className="mb-20">{languages.portfolio}</h1>
                  <h5 className="uppercase">{languages.my_works}</h5>
                </div>
              </div>
            </div>
            {/* <!--End row--> */}
          </div>
          {/* <!--End block content-->
            <!--Block content--> */}
          <div className="block-content mb-100">
            {/* <!--Works--> */}
            <div className="works">
              {/* <!--Row--> */}
              <div className="row">
                <div className="col-md-12">
                  {/* <!--Block filter--> */}
                  <div className="block-filter uppercase mb-40">
                    <ul className="filter" id="category">
                      <li>
                        <a
                          className={`${!category && 'active'}`}
                          style={{ cursor: "pointer" }}
                          data-filter="all"
                          onClick={() => setCategory(null)}
                        >
                          {languages.all}
                        </a>
                      </li>
                      {categoriesApp.map(oCat => <li key={oCat.id}>
                        <a style={{ 
                          cursor: "pointer",

                         }}
                         className={`${(category === oCat.id) && 'active'}`} 
                         data-filter={oCat.id} 
                         onClick={() => setCategory(oCat.id)}>
                          {oCat.label}
                        </a>
                      </li>)}
                    </ul>
                  </div>
                  {/* <!--End block filter--> */}
                </div>
              </div>
              {/* <!--End row--> */}
              {/* <!--Row--> */}
              <div className="row">
                <div className="col-md-12">
                  <ul className="work not-mb-spec">
                    {filteredApps.map((oElt, idx) => (
                      <WebAppCoverElt {...oElt} key={idx} />
                    ))}

                    
                    <li className="col-md-6"></li>
                  </ul>
                </div>
              </div>
              {/* <!--End row--> */}
            </div>
            {/* <!--End works--> */}
          </div>
          {/* <!--End block content--> */}
          {/* <!--Block content--> */}
          <div className="block-content t-center">
            {/* <!--Row--> */}
            <div className="row">
              <div className="col-md-12">
                <span className="page-number emph-1">- 3/5 -</span>
              </div>
            </div>
            {/* <!--End row--> */}
          </div>
          {/* <!--End block content--> */}
        </div>
        {/* <!--End content--> */}
      </div>
      {/* <!--End portfolio--> */}
    </section>
  );
};

export default MyPortfolioPage;

const WebAppCoverElt = ({
  category,
  description,
  framework,
  id,
  imagePath,
  mobileFramework,
  name,
  websiteLink,
  year,
  androidLink,
  apiFramework,
  database,
  iosLink,
}: IAppRecord) => {
  const categoryName =
    category === "website"
      ? languages.website
      : category === "webapp"
      ? languages.webapp
      : category === "mobile"
      ? languages.mobile_app
      : category === "wordpress"
      ? "Wordpress"
      : category === "webmobile"
      ? languages.web_and_mmobile
      : languages.website;
  return (
    <li className="col-md-6 ">
      <div className="item web">
        <a href={websiteLink} target="_blank" rel="noreferrer">
          <div className="desc">
            <h5 className="proj-desc bold">
              {name}
              <span>{categoryName}</span>
            </h5>
          </div>
          <img src={imagePath} alt="" />
        </a>
      </div>
      <div className="project-descript">
        <div
          className="w-flex proj-categ"
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <div className="p-title">{languages.category}:</div>
          <div className="p-body">{categoryName}</div>
        </div>
        <div
          className="w-flex proj-categ"
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <div className="p-title">{languages.year}:</div>
          <div className="p-body">
            <span>{year}</span>
          </div>
        </div>
        <div
          className="w-flex proj-categ"
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <div className="p-title">{languages.description}:</div>
          <div className="p-body">
            <span style={{ height: 60 }}>{description}</span>
          </div>
        </div>
        <div
          className="w-flex proj-categ"
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <div className="p-title">{languages.framework}:</div>
          <div className="p-body">{framework ?? "-"}</div>
        </div>
        <div
          className="w-flex proj-categ"
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <div className="p-title">{languages.database}:</div>
          <div className="p-body">{database ?? "-"}</div>
        </div>
        <div
          className="w-flex proj-categ"
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <div className="p-title">{languages.web_site}:</div>
          <div className="p-body">
            {websiteLink ? <a href={websiteLink} target="_blank" rel="noreferrer">
              {appFxs.shortenText(websiteLink.replace("https://", ""))}
            </a>: '-'}
          </div>
        </div>
        <div
          className="w-flex proj-categ"
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <div className="p-title">{languages.api_framework}:</div>
          <div className="p-body">{apiFramework ?? "-"}</div>
        </div>
        <div
          className="w-flex proj-categ"
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <div className="p-title">{languages.mobile_framework}:</div>
          <div className="p-body">{mobileFramework ?? "-"}</div>
        </div>
        <div
          className="w-flex proj-categ"
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <div className="p-title">{languages.ios_link}:</div>
          <div className="p-body">
            {iosLink ? (
              <a href={iosLink} target="_blank" rel="noreferrer">
                {appFxs.shortenText(iosLink.replace("https://", ""))}
              </a>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div
          className="w-flex proj-categ"
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          <div className="p-title">{languages.android_link}:</div>
          <div className="p-body">
            {androidLink ? (
              <a href={androidLink} target="_blank" rel="noreferrer">
                {appFxs.shortenText(androidLink.replace("https://", ""))}
              </a>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
    </li>
  );
};
