import React from 'react'

const LoadingPage = () => {
  return (
    <div style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center'
    }}>
        <i className='fa fa-spinner fa-spin fa-3x'></i>
    </div>
  )
}

export default LoadingPage